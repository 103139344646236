<template>

  <div style="height: 100vh">
    <iframe style="height: 100%; width:100%; border:0px!important;" src='javascript:window.location.replace("https://ls.sir.sportradar.com/tucheze")'></iframe>
  </div>
</template>

<script>

export default {
  name: 'Livescore',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","results");

  }

}
</script>